import React, { useState } from "react"
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from "gatsby"

import { colors, storage } from "../../settings"

import LettersImage from "../images/categories/channel-letters-and-logos"
import LitShapesImage from "../images/categories/lit-shapes"
import CabinetsImage from "../images/categories/lit-cabinets"
import UnlitImage from "../images/categories/unlit"

import HaloImage from "../images/products/halo-channel-letters-shapes"
import CabinetDoubleImage  from "../images/products/lit-cabinet-retainer-double"
import CabinetSingleImage from "../images/products/lit-cabinet-retainer-single"
import ChannelLettersRacewayImage from "../images/products/lit-channel-letters-shapes-raceway"
import ChannelLettersImage from "../images/products/lit-channel-letters-shapes"
import HaloLitImage from "../images/products/lit-halo-channel-letters-shapes"
import LitShapeImage from "../images/products/lit-shape-sign"

const Columns = styled.div`
  display: flex;
  padding-top: 10px;
  min-height: 300px;
`
const CategoriesColumn = styled.div`
  flex-basis: 30%;
  padding-left: 10px;
`
const ProductsColumn = styled.div`
  flex-basis: 35%;
  padding: 10px;
`
const ProductColumn = styled.div`
  flex-basis: 45%;

  background-image: url(${props => props.image });
  background-size: contain;
  background-repeat: no-repeat;
`
const Category = styled.div`
  padding: 10px 0px;
  
  a {    
    display: flex;  
    align-items: center;
    color: #ccc;  
    padding-right: 10px;
    cursor: pointer;

    border-bottom: 3px solid white;

    > div {
        width: 100px !important;
    }
  }

  img {
    margin-right: 5px;
    filter: grayscale(1);
    opacity: .6;
  }
  label {
      flex: 1;
      align-self: center;
      cursor: pointer;
  }

  &.active {
    a {
        font-weight: bold;
        color: black;
    }

    img {
        filter: grayscale(0);
        opacity: 1;
    }
  }  
`
const Product = styled.div`
  padding: 5px;
  border-bottom: 2px solid white;

  a {    
    display: flex;  
    color: black;  
    cursor: pointer;

    img {
        height: 32px;
        width: auto;
        margin-right: 10px;
        cursor: pointer;
    }
  }

  label {
      flex: 1;
      align-self: center;
  }

  &:hover {
      border-color: ${colors.red};
  }  
  &.active {
    a {
        font-weight: bold;
    }
    border-color: ${colors.red};
}  
`

function ProductsPanel(props) {
    const data = useStaticQuery(graphql`
        {
            products: allMysqlProducts(sort: {fields: list_order, order: ASC}) {
                edges {
                    product: node {
                        id
                        name
                        url
                        title
                        short_title
                        category_id
                    }
                }
            }
        }
    `);

    const [category, setCategory] = useState(1);//(data.categories.edges[0].cat);
    const [product, setProduct] = useState(data.products.edges.filter(x => x.product.category_id == 1)[0].product);

    function onMouseOver(type, item, event) {
        if (type === "category") {
            setCategory(item);

            if (product && product.category_id != item)
                setProduct(data.products.edges.filter(x => x.product.category_id == item)[0].product)
        }
        else if (type === "product")
            setProduct(item);
    }
    function onMouseLeave(type, item, event) {
        //setCategoryId("");
    }

    const products = data.products.edges.filter(x => x.product.category_id == category);

    return (
        <Columns>
            <CategoriesColumn>
                <Category 
                    key={1} 
                    className={category == 1 ? "active":""}
                    onMouseOver={(e) => onMouseOver("category", 1, e)}
                    onMouseLeave={(e) => onMouseLeave("category", 1, e)} 
                >
                    <Link to={"/products/lit-channel-letters-and-logos/"} onClick={props.linkClicked}>
                        <div>
                            {false && <img src={storage.url + "2020/categories/channel-letters-and-logos-small.jpg"} alt="Lit Channel Letters and Logos" loading="lazy" />}                        
                            <LettersImage />
                        </div>
                        <label>Lit Letters &amp; Logos</label>
                    </Link>
                </Category>
                <Category 
                    key={2} 
                    className={category == 2 ? "active":""}
                    onMouseOver={(e) => onMouseOver("category", 2, e)}
                    onMouseLeave={(e) => onMouseLeave("category", 2, e)} 
                >
                    <Link to={"/products/lit-shapes/"} onClick={props.linkClicked}>
                        <div>
                            <LitShapesImage />
                        </div>
                        <label>Lit Shape Signs</label>
                    </Link>
                </Category>
                <Category 
                    key={3} 
                    className={category == 3 ? "active":""}
                    onMouseOver={(e) => onMouseOver("category", 3, e)}
                    onMouseLeave={(e) => onMouseLeave("category", 3, e)} 
                >
                    <Link to={"/products/lit-cabinets/"} onClick={props.linkClicked}>
                        <div>
                            <CabinetsImage />
                        </div>
                        <label>Lit Cabinet Signs</label>
                    </Link>
                </Category>
                <Category 
                    key={100175} 
                    className={category == 100175 ? "active":""}
                    onMouseOver={(e) => onMouseOver("category", 100175, e)}
                    onMouseLeave={(e) => onMouseLeave("category", 100175, e)} 
                >
                    <Link to={"/products/dimensional-letters-and-logos/"} onClick={props.linkClicked}>
                        <div>
                            <UnlitImage />
                        </div>
                        <label>Dimensional Letters and Logos</label>
                    </Link>
                </Category>                
            </CategoriesColumn>
            <ProductsColumn>
                {products.map((item) => (
                <Product 
                    key={item.product.id} 
                    className={item.product.id == product.id ? "active":""}
                    onMouseOver={(e) => onMouseOver("product", item.product, e)}
                    onMouseLeave={(e) => onMouseLeave("product", item.product, e)} 
                >
                    <Link to={"/products/" + item.product.url + "/"} onClick={props.linkClicked}>
                        <img src={storage.url + "2020/products/" + item.product.name + "/product.jpg?v6"} alt={item.product.title} loading="lazy" />
                        <label>{ item.product.short_title}</label>
                    </Link>
                </Product>
                ))}
            </ProductsColumn>
            <ProductColumn image={storage.url + "2020/products/" + product.name + "/productlarge.jpg?v6"}>
                
            </ProductColumn>
        </Columns>
    );
}

export default ProductsPanel